import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import image from '../../images/js-event-2022.png';

export default class Home extends Component {
	render() {
		return (
			<div>
				<div className="give-me-space blog">
					<h2>
						Voorinschrijvingen seizoen 2023-2024
					</h2>
					<p className="normal">
						Heb jij onze posters gezien, en ben je getriggerd?
						Of was je altijd al benieuwd naar ultimate frisbee?
						Ben je nog op zoek naar een nieuwe sport voor jou en/of je kind(eren)?
						En hou je van een dynamische, explosieve en unieke teamsport?
						Dan willen wij je graag verwelkomen in onze club.
						We bieden ultimate frisbee aan voor groot en klein (meer info onder de tekening).
						Heb je interesse? Laat dan <a className='link' href=" https://forms.gle/AgVzD6sQNrLxrK7fA">hier</a> je gegevens achter, zodat we je kunnen contacteren voor de start van het nieuwe seizoen in september. Tot binnenkort!
					</p>

					<h2>
						Jetset Take-off
					</h2>
					<p className="normal">
						Op 24 september organiseren we een toernooi voor vrienden en familie van onze leden. Heb jij ook zin om onze sport met hen te delen? Vind een paar vrienden, familieleden of collega's en schrijf je NU in! Je kan jezelf individueel of als (onvolledig) team inschrijven. Meer informatie vind je <a className='link' href="https://docs.google.com/forms/d/e/1FAIpQLSe6QDGZ8TzEvmeatduHUW9tmHWNx7AMZjjkojL5MgSsngvMQQ/viewform">hier</a>.
					</p>
					<img src={image} alt="jetset-takeoff-2022" style={{ width: '100% ' }} />


					<h2>Open trainingen U11, U14/U17, U20 en volwassenen</h2>
					<p>
						Ben je op zoek naar een nieuwe sport voor jezelf of jouw kinderen? Ben je nog niet zeker of het iets voor jou of voor jouw kinderen is? In september kan je het gratis komen uitproberen! Bekijk de Facebookevementen voor meer informatie:
						<b><a className='link' href="https://fb.me/e/2NtL2QNwe">U11</a></b>,
						<b><a className='link' href="https://fb.me/e/5daph8Fum">U14/U17</a></b>,
						<b><a className='link' href="https://fb.me/e/2Ubx4Lwk5">U20</a></b>,
						<b><a className='link' href="https://fb.me/e/2ar6M5jHL">VOLWASSENEN</a></b>
						<br />
						Heb je interesse? Laat dan <a className='link' href="https://forms.gle/AgVzD6sQNrLxrK7fA">hier</a> je gegevens achter, zodat we je kunnen contacteren voor de start van het nieuwe seizoen in september. Tot binnenkort!
					</p>


					<h2>In welke ploeg kom je terecht?</h2>
					<b>U11 - Balloons (geboren in 2012-2013):</b>
					<p>
						De U11 wordt binnen onze club de Balloons genoemd. Kinderen zijn welkom van zodra ze 8 jaar oud zijn. De U11 komen elke week samen op maandag en trainen binnen. Hier worden spelenderwijs de verschillende worpen en spelvormen aangeleerd.
					</p>
					<b>U14 - ULM (geboren in 2009-2011):</b>
					<p>
						De U14 wordt binnen onze club de ULM genoemd. Zij trainen op dinsdag en donderdag. Hier wordt al meer gefocust op tactieken. Enkele keren per jaar wordt meegedaan aan toernooien.
					</p>
					<b>U17 - ULM Alpha Jet (geboren in 2006-2008):</b>
					<p>
						De U17 wordt binnen onze club Alpha Jet genoemd. Zij trainen op dinsdag en op donderdag. Zij werken meer naar toernooien toe.
					</p>
					<b>U20 (geboren in 2003-2005):</b>
					<p>
						Vanaf de U20 staat het competitieve centraal. Zij hebben training op donderdag. Ze kunnen naargelang hun niveau een tweede training met de volwassenen volgen.
					</p>
					<b>De Zeppelins (geboren in 2004 of ouder):</b> zijn onze beginnersploeg voor volwassenen. Hier starten nieuwe leden. De trainingen gaan door op dinsdag. Hier wordt gefocust op leren gooien en een introductie tot de regels en tactieken van het spel.
					<p>Voor nog meer uitleg over de verschillende teams kan je <Link className='link' to="/ploegen">hier</Link> terecht.</p>

					<h2>Wanneer train je?</h2>
					<p>Je kan alle trainingsmomenten terugvinden in de <Link className='link' to="/trainingsschema">agenda</Link>.</p>

				</div>
			</div >
		);
	}
}
