import React, { Component } from 'react';
import { Grid, Row } from 'react-bootstrap';
// import { Link } from 'react-router-dom';

// import voorzitter from '../../images/Voorzitter.png';
// import peningmeester from '../../images/Peningmeester.png';
// import secretaris from '../../images/Secretaris.png';
// import redactie from '../../images/Redactie.png';
// import site from '../../images/Site.png';
// import outfits from '../../images/Arthur.jpg';
import buf from '../../images/BUF.png';
import fros from '../../images/fros.png';
import tofsport from '../../images/tofsport.png';
import buurtsport from '../../images/buurtsport.png';
import vluff from '../../images/vluff.png';
import OpenSport from '../../images/OpenSport.png';
import Jeugdvriendelijke from '../../images/jeugdvriendelijke_club.png';

import ContactGroup from '../../components/ContactGroup';

export default class Contact extends Component {
	render() {
		return (
			<div>
				<h2>Algemeen</h2>
				<div className="contact-group">
					<p>
						{`Jetset Ultimate frisbee vzw
                    Goudbloemstraat 32 box 301
                    3000 Leuven`}
					</p>
					<p>
						{`IBAN: BE25 7360 3918 0182
                    BIC/SWIFT: KREDBEBB
                    info@jetsetultimate.be`}
					</p>
				</div>
				<p>
					Onze club is aangesloten bij de {' '}
					<a className="link" href="http://www.fros.be">FROS amateursport</a> federatie waardoor
					we verbonden zijn voor de verzekering aan Ethias.
				</p>

				<h2>Initiaties</h2>
				<p>
					<span className="highlight">
						Wij bieden initiaties aan in regio Leuven{' '}
					</span>
					(straal van 15 km rond Leuven). Idealiter werken wij met een
					doorschuifsysteem, waar we groepen van 10 tot 20 personen hebben voor
					1 tot 2 uur. Qua leeftijd raden wij ultimate frisbee aan vanaf het 4e
					leerjaar lagere school. Natuurlijk zijn wij hierin flexibel en kunnen
					wij ons aanpassen afhankelijk van jullie vraag.
				</p>
				<p>
					<span className="highlight">
						Onze initiatoren hebben jarenlange ervaring met ultimate frisbee.
					</span>{' '}
					Via de club hebben ze al doende (en de meeste ook via een
					trainerscursus) geleerd hoe ultimate aangebracht kan worden. De
					trainers beheersen het vak zelf door en door en brengen het over met
					passie. Om deze kwaliteit te garanderen, bestaat de kans dat er soms
					een tweede persoon mee komt naar een initiatie om op die manier ook
					hierin te scholen.
				</p>
				<p>
					<span className="highlight">Wat brengen we mee? </span>
					We voorzien 1 officiële frisbee per 2 personen, kegels en toebehoren.
					Voor groepen uit de basisschool werken we met aangepaste (lichtere en
					zachtere) frisbees.
				</p>
				<p>
					<span className="highlight">Hoeveel kost het? </span>
					Wij voorzien initiaties in verschillende formules voor 30€/u. Dit
					tarief omvat 1 initiator voor de initiatie, de verplaatsing en het
					materiaal. Springuren kunnen ook in rekening worden gebracht.
				</p>
				<p>
					<span className="highlight">En in de zomer? </span>
					Daarnaast organiseert onze club tijdens de zomermaanden ook
					frisbeekamp in samenwerking met tofsport Leuven.
				</p>

				<h2>Bestuur</h2>

				<Grid>
					<Row>
						<ContactGroup
							title={'Voorzitter'}
							name={'Floor Van Damme'}
							contactInfo={`info@jetsetultimate.be`}
							responsibilities={
								'Algemene vragen, verzekeringen, activiteiten opvolgen'
							}
						/>
						<ContactGroup
							title={'Penningmeester'}
							name={'Arthur De Rieck'}
							contactInfo={'financien@jetsetultimate.be'}
							responsibilities={'Financiën, opvolgen ledenzone'}
						/>
						<ContactGroup
							title={'Secretaris'}
							name={'Tine Geldhof'}
							contactInfo={'info@jetsetultimate.be'}
							responsibilities={'Velden, contact stad leuven'}
						/>
					</Row>
				</Grid>

				<h2>Trainers</h2>
				<Grid>
					<Row>
						<ContactGroup
							title={<a href="/ploegen/#open">Open</a>}
							name={'August Smessaert, Emiel De Rieck, Max Viaene, Daan De Marrée & Tim Op de Beeck'}
							contactInfo={'jetsetopen@jetsetultimate.be'}
						/>
						<ContactGroup
							title={<a href="/ploegen/#mixed">Mixed</a>}
							name={'Saskia Chambille, Sander Smets, Paul Lacko & Wouter Duverger'}
							contactInfo={'mixed@jetsetultimate.be'}
						/>
						<ContactGroup
							title={<a href="/ploegen/#luv">LUV</a>}
							name={'Silke Delafortrie'}
							contactInfo={'luv@jetsetultimate.be'}
						/>
						<ContactGroup
							title={<a href="/ploegen/#gold">Gold</a>}
							name={'Freek Janssens'}
							contactInfo={'gold@jetsetultimate.be'}
						/>
						<ContactGroup
							title={<a href="/ploegen/#propellers">Propellers</a>}
							name={'Arthur De Rieck & Robrecht Bollen'}
							contactInfo={'propellers@jetsetultimate.be'}
						/>

						<ContactGroup
							title={<a href="/ploegen/#zeppelins">Zeppelins</a>}
							name={'Bieke Vandaele & Robin Goris'}
							contactInfo={'zeppelins@jetsetultimate.be'}
						/>
						<ContactGroup
							title={<a href="/ploegen#u11-balloons">Balloons</a>}
							name={'Rik De Vos'}
							contactInfo={'jeugdcoordinator@jetsetultimate.be'}
						/>
						<ContactGroup
							title={<a href="/ploegen/#u14-ulm">U-14 ULM</a>}
							name={
								'Bert Claessens, Rik de Vos'
							}
							contactInfo={'jeugdcoordinator@jetsetultimate.be'}
						/>
						<ContactGroup
							title={<a href="/ploegen/#u17-ulm">U-17 Alpha Jet</a>}
							name={
								'Hans Geyens, Karl Kestens & Robert Brown'
							}
							contactInfo={'jeugdcoordinator@jetsetultimate.be'}
						/>
						<ContactGroup
							title={<a href="/ploegen/#u20">U-20</a>}
							name={'Katharina Meissl, Jesse Luykx & Ward Fock'}
							contactInfo={'jeugdcoordinator@jetsetultimate.be'}
						/>

					</Row>
				</Grid>


				<h2>Jeugdcoördinator</h2>

				<Grid>
					<Row>
						<ContactGroup
							title={'Jeugdcoördinator'}
							name={'Lien Desmet & Lena De Wit'}
							contactInfo={'jeugdcoordinator@jetsetultimate.be'}
						/>
					</Row>
				</Grid>


				<h2>Vertrouwenspersoon</h2>
				<p>
					Ondervind je wat strubbelingen met één van je trainers of medespelers,
					heb je het gevoel dat je niet aanvaard of slecht behandeld wordt,
					of ben je zelf slachtoffer of getuige van grensoverschrijdend gedrag?
					Spreek dan één van de vertrouwenspersonen van de club aan. Dit kan uiteraard via mail,
					maar dat mag zeker ook persoonlijk of via een ander kanaal, waar jij je het beste bij voelt.
				</p>

				<Grid>
					<Row>
						<ContactGroup
							name={'Freek Janssens'}
							contactInfo={'vertrouwenspersoon@jetsetultimate.be'}
							responsibilities={
								'Bemiddelaar en expert in clubhistorische ontwikkelingen'
							}
						/>
						<ContactGroup
							name={'Astrid Vangerven'}
							contactInfo={'vertrouwenspersoon@jetsetultimate.be'}
							responsibilities={
								'Bemiddelaar en aanspreekpunt diversiteit en integriteit'
							}
						/>

					</Row>
				</Grid>

				<h2>Links</h2>
				<Grid>
					<Row>
						<ContactGroup
							// title={
							// 	<a className="link" href="http://www.belgianultimate.be">Belgian Ultimate</a>
							// }
							name={'Belgische federatie'}
							contactInfo={'info@belgianultimate.be'}
							responsibilities={
								'Organisatie nationale ploegen en nationale kampioenschappen'
							}
							imageLink={'http://www.belgianultimate.be'}
							imageSrc={buf}
						/>

						<ContactGroup
							// title={<a className="link" href="http://www.fros.be">FROS</a>}
							name={'Amaterusportfederatie'}
							contactInfo={'info@fros.be'}
							responsibilities={
								'Dé recreatieve multisportfederatie, zij voorzien ook de verzekering en jeugdondersteuning.'
							}
							imageLink={'http://www.fros.be'}
							imageSrc={fros}
						/>
						<ContactGroup
							// title={
							// 	<a className="link" href="http://www.tofsport.be/activiteiten/tofsportkampen/">
							// 		Tofsportkampen
							// 	</a>
							// }
							name={'Tofsport Leuven'}
							contactInfo={'tofsport@leuven.be'}
							responsibilities={
								'Sportkampen die we samen met de stad Leuven organiseren'
							}
							imageLink={'http://www.tofsport.be/activiteiten/tofsportkampen/'}
							imageSrc={tofsport}
						/>

						<ContactGroup
							// title={
							// 	<a className="link" href="http://frisbeefederatie.wixsite.com/vluff">
							// 		Frisbee Vlaanderen
							// 	</a>
							// }
							name={'Vlaamse Federatie'}
							contactInfo={'vlaamsefrisbeefederatie@gmail.com'}
							responsibilities={
								'Aansluiting leden, Vertegenwoordiging in Vlaanderen'
							}
							imageLink={'http://frisbeefederatie.wixsite.com/vluff'}
							imageSrc={vluff}
						/>
						<ContactGroup
							// title={
							// 	<a className="link" href="https://www.tofsport.be/sportverenigingen/open-sportclub/">
							// 		Frisbee Vlaanderen
							// 	</a>
							// }
							name={'Open sportclub'}
							// contactInfo={'vlaamsefrisbeefederatie@gmail.com'}
							responsibilities={
								'Jetset streeft ernaar om ultimate frisbee voor iedereen aan te bieden.\
								We zoeken daarom naar oplossingen op maat voor personen of jongeren uit een sociaal \
								of maatschappelijk moeilijke(re) positie, of voor personen met een beperking. \
								Contacteer het bestuur voor een persoonlijk gesprek wanneer je hier vragen over hebt.'
							}
							imageLink={'https://www.tofsport.be/sportverenigingen/open-sportclub/'}
							imageSrc={OpenSport}
						/>
						<ContactGroup
							name={'Jeugdvriendelijke club'}
							imageSrc={Jeugdvriendelijke}
						/>
						<ContactGroup
							// title={
							// 	<a className="link" href="http://www.tofsport.be/activiteiten/buurtsport/">
							// 		Buurtsport
							// 	</a>
							// }
							name={'Buurtsport Leuven'}
							contactInfo={'buurtsport@leuven.be'}
							responsibilities={
								'De brug tussen reguliere sportclubs en maatschappelijk kwetsbare personen'
							}
							imageLink={'http://www.tofsport.be/activiteiten/buurtsport/'}
							imageSrc={buurtsport}
						/>
					</Row>
				</Grid>
			</div>
		);
	}
}
